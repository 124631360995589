import React, { useState, useEffect } from 'react'
import { getCode, getData } from 'country-list'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CInputGroup,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { reqAddSubIntrest } from 'src/store/action'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const AddSubInterest = () => {
  const { id } = useParams()
  // const countryList = getData()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { register, handleSubmit, watch } = useForm()
  const [ckData, setCkData] = useState({ Conditions: '' })
  const [error, setError] = useState(false)
  const toast = useSelector((state) => state.Intrest?.toast)
  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_INTEREST_TOAST', payload: { visible: false, type: '', message: '' } })
        if (toast.type == 'success') {
          navigate(-1)
        }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])
  const onSubmit = async (data) => {
    if (!ckData.Conditions.trim()) {
      setError(true) // Show error if Conditions is empty
    } else {
      setError(false)
      const name = data.name
      // const countryCode = getCode(data.country)
      const status = data.status
      const imageUrl = data.imageUrl && data.imageUrl[0]
      const description = data.description
      const description_2 = data.description_2
      const description_3 = data.description_3
      const Conditions = ckData.Conditions
      const newData = {
        name,
        // countryCode,
        status,
        imageUrl,
        interestId: id,
        description,
        description_2,
        description_3,
        Conditions,
      }
      // Create a FormData instance
      const formData = new FormData()

      // Append each property to FormData
      formData.append('interestId', newData.interestId)
      formData.append('name', newData.name)
      formData.append('description', newData.description)
      formData.append('description_2', newData.description_2)
      formData.append('description_3', newData.description_3)
      formData.append('status', newData.status)
      formData.append('Conditions', newData.Conditions)
      // Handle imageUrl conditionally
      if (newData.imageUrl && newData.imageUrl.length > 0) {
        formData.append('imageUrl', newData.imageUrl)
      } else {
        formData.append('imageUrl', newData.imageUrl || '')
      }
      console.log(formData, 'formData')
      dispatch(reqAddSubIntrest(newData, navigate))
    }
  }
  console.log(id)
  return (
    <CRow>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="light" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <br />
          <CCol xs={16}>
            <CCard className="mb-4">
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Service name*</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('name', { required: true })}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Conditions*</label>
                    <CCol sm={10}>
                      {/* <input
                        className="form-control"
                        {...register('Conditions', { required: true })}
                        type="text"
                        required
                      /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data=""
                        config={{
                          removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
                        }}
                        onChange={(event, editor) => {
                          let data = editor.getData()
                          data = data
                          setCkData({ ...ckData, Conditions: data })
                          if (error) setError(false)
                        }}
                        placeholder="Conditions"
                      />
                      {error && (
                        <p style={{ color: 'red', marginTop: '8px' }}>
                          Conditions field is required!
                        </p>
                      )}
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Description 1*</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('description', { required: true })}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Description 2</label>
                    <CCol sm={10}>
                      <input className="form-control" {...register('description_2')} type="text" />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Description 3</label>
                    <CCol sm={10}>
                      <input className="form-control" {...register('description_3')} type="text" />
                    </CCol>
                  </CRow>
                  {/* <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Country*</label>
                    <CCol sm={10}>
                      <select
                        className="form-control m-2"
                        required
                        {...register('country', { required: true })}
                      >
                        <option value="">Choose country</option>
                        {countryList?.map((country) => (
                          <option key={country.code}>{country.name}</option>
                        ))}
                      </select>
                    </CCol>
                  </CRow> */}
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Interest image*</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('imageUrl')}
                        type="file"
                        accept="image/png image/jpeg image/jpg"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <CCol sm={10}>
                      <label
                        className="m-2"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                      >
                        <input
                          className="mr-2"
                          type="radio"
                          value={1}
                          {...register('status', { required: true })}
                        />
                        Active
                      </label>
                      <label
                        className="m-2"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                      >
                        <input
                          className="mr-2"
                          type="radio"
                          value={0}
                          {...register('status', { required: true })}
                        />
                        Inactive
                      </label>
                    </CCol>
                  </CRow>

                  <CRow>
                    <div className="d-grid gap-2">
                      <CButton color="light" size="sm" type="submit">
                        Add
                      </CButton>
                      <CButton color="light" size="sm" onClick={() => navigate(-1)}>
                        Cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCol>
        </div>
        <AppFooter />
      </div>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </CRow>
  )
}

export default AddSubInterest
