// src/components/AddBanner.js

import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { reqAddBanner, reqBannerList, reqCountryList } from 'src/store/action'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { Description } from '@mui/icons-material'
import { getCode, getData } from 'country-list'
const AddBanner = () => {
  const countryList = getData()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { register, handleSubmit, reset } = useForm()
  const toast = useSelector((state) => state.Banner.toast)
  const Country_data = useSelector((state) => state.Country?.countries)
  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_TOAST', payload: { visible: false, type: '', message: '' } })
        if (toast.type == 'success') {
          navigate(-1)
        }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])

  const onSubmit = async (data) => {
    const description = data.description
    const countryCode = getCode(data.country)
    const status = data.status
    const country = data.country
    const destinationUrl = data.destinationUrl
    const imagePath = data.imagePath && data.imagePath[0]
    const newData = {
      description,
      countryCode,
      status,
      imagePath,
      country,
      destinationUrl,
    }
    // Create a FormData instance
    const formData = new FormData()

    // Append each property to FormData
    formData.append('description', newData.description)
    formData.append('countryCode', newData.countryCode)
    formData.append('status', newData.status)
    formData.append('country', newData.country)
    formData.append('destinationUrl', newData.destinationUrl)
    // Handle imageUrl conditionally
    if (newData.imagePath && newData.imagePath.length > 0) {
      formData.append('imagePath', newData.imagePath)
    } else {
      formData.append('imagePath', newData.imagePath || '')
    }
    dispatch(reqAddBanner(newData, navigate))
  }
  useEffect(() => {
    dispatch(reqCountryList())
  }, [dispatch])
  return (
    <CRow>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="light" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <br />
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Banner image *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('imagePath', { required: true })}
                        type="file"
                        accept="image/png image/jpeg image/jpg"
                        required
                      />
                    </CCol>
                  </CRow>

                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Banner Description *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('description', { required: true })}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow>
                  {/* <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Country</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('country')}
                        type="text"
                        required
                      />
                    </CCol>
                  </CRow> */}
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Country *</label>
                    <CCol sm={10}>
                      <select
                        className="form-control m-2"
                        required
                        {...register('country', { required: true })}
                      >
                        <option value="">Choose country</option>
                        {Country_data?.map((country) => (
                          <option key={country.countryCode}>{country.countryName}</option>
                        ))}
                      </select>
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Destination Url *</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        type="text"
                        {...register('destinationUrl')}
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <CCol sm={10}>
                      <label
                        className="m-2"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                      >
                        <input type="radio" value={1} {...register('status', { required: true })} />{' '}
                        Active
                      </label>
                      <label
                        className="m-2"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                      >
                        <input type="radio" value={0} {...register('status', { required: true })} />{' '}
                        Inactive
                      </label>
                    </CCol>
                  </CRow>
                  <CRow>
                    <div className="d-grid gap-2">
                      <CButton color="light" type="submit">
                        {id ? 'Edit' : 'Add'} Banner
                      </CButton>
                      <CButton color="light" onClick={() => navigate(-1)}>
                        Cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCol>
        </div>
      </div>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </CRow>
  )
}

export default AddBanner
