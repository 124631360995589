import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CCardBody, CCol, CRow, CButton, CModal, CModalBody, CModalFooter } from '@coreui/react'
import dummyInterest from '../interest/dummyData'
import { reqSubIntrestUpdate, reqSubIntrestDetails } from 'src/store/action'
import { useDispatch, useSelector } from 'react-redux'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const UpdateSubInterest = () => {
  const { id, subInterestId } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [ckData, setCkData] = useState({ Conditions: '' })
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const { register, handleSubmit, reset, watch } = useForm()
  const [OldData, setOldData] = useState(null)
  const details = useSelector((state) => state.Intrest?.getSubintrestDetails?.subServices[0])
  useEffect(() => {
    dispatch(reqSubIntrestDetails({ id, subInterestId }))
  }, [dispatch, id, subInterestId])

  useEffect(() => {
    details &&
      details.subInterest &&
      details.subInterest.length > 0 &&
      setTimeout(() => {
        setOldData({ ...details.subInterest[0] })
      }, 1000)
  }, [details])
  useEffect(() => {
    if (OldData) {
      let data = OldData
      data.status = data.status === 1 ? 'active' : 'deactive'
      reset(data)
    }
  }, [reset, OldData])
  const toast = useSelector((state) => state.Intrest?.toast)
  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_INTEREST_TOAST', payload: { visible: false, type: '', message: '' } })
        if (toast.type == 'success') {
          navigate(-1)
        }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])
  const onSubmit = async (data) => {
    console.log(data, 'data')
    if (!ckData.Conditions.trim()) {
      setError(true) // Show error if Conditions is empty
    } else {
      const finalData = {
        interestId: id,
        subInterestId,
        name: data.name,
        description: data.description,
        description_2: data.description_2,
        description_3: data.description_3,
        imageUrl: data.imageUrl && data.imageUrl.length > 0 ? data.imageUrl[0] : data.imageUrl,
        status: data.status,
        Conditions: ckData.Conditions,
      }
      // Create a FormData instance
      let formData = new FormData()

      // Append each property to FormData
      formData.append('interestId', finalData.interestId)
      formData.append('subInterestId', finalData.subInterestId)
      formData.append('name', finalData.name)
      formData.append('status', finalData.status === 'active' ? 1 : 0)
      formData.append('description', finalData.description)
      formData.append('description_2', finalData.description_2)
      formData.append('description_3', finalData.description_3)
      formData.append('Conditions', finalData.Conditions)
      // Handle imageUrl conditionally
      if (finalData.imageUrl && data.imageUrl.length > 0) {
        formData.append('imageUrl', finalData.imageUrl)
      } else {
        formData.append('imageUrl', finalData.imageUrl || '')
      }
      const response = dispatch(reqSubIntrestUpdate(finalData, navigate))
    }
  }

  const statusValue = watch('status', OldData?.status)
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <CRow>
            <CCol xs={12}>
              <CCardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Sub Interest Name*</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('name')}
                        type="String"
                        placeholder="Interest Name"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Conditions*</label>
                    <CCol sm={10}>
                      {/* <input
                        className="form-control m-1 p-2"
                        {...register('Conditions')}
                        type="String"
                        placeholder="Conditions"
                        required
                      /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data={OldData?.Conditions}
                        onChange={(event, editor) => {
                          let data = editor.getData()
                          data = data
                          setCkData({ ...ckData, Conditions: data })
                        }}
                        placeholder="Conditions"
                      />
                      {error && (
                        <p style={{ color: 'red', marginTop: '8px' }}>
                          Conditions field is required!
                        </p>
                      )}
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Description 1*</label>
                    <CCol sm={10}>
                      <input
                        className="form-control m-1 p-2"
                        {...register('description')}
                        type="String"
                        placeholder="Description"
                        required
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Description 2</label>
                    <CCol sm={10}>
                      <input className="form-control" {...register('description_2')} type="text" />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Description 3</label>
                    <CCol sm={10}>
                      <input className="form-control" {...register('description_3')} type="text" />
                    </CCol>
                  </CRow>
                  <CRow className="form-group p-2">
                    <label className="col-sm-2 col-form-label">Interest image*</label>
                    <CCol sm={10}>
                      <input
                        className="form-control"
                        {...register('imageUrl')}
                        type="file"
                        accept="image/png image/jpeg image/jpg"
                        required={!OldData?.imageUrl}
                      />
                      <img
                        src={OldData?.imageUrl || ''}
                        style={{
                          width: '70px',
                          height: '70px',
                          objectFit: 'cover',
                          borderRadius: '5px',
                        }}
                        alt="Interest"
                      />
                    </CCol>
                  </CRow>
                  <CRow className="form-group">
                    <label className="col-sm-2 col-form-label">Status</label>
                    <CCol sm={10}>
                      <label
                        className="m-2"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                      >
                        <input
                          className="m-2"
                          type="radio"
                          value={'active'}
                          {...register('status', { required: true })}
                          // defaultChecked={OldData?.status === 1}
                        />
                        Active
                      </label>
                      <label
                        className="m-2"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                      >
                        <input
                          className="m-2"
                          type="radio"
                          value={'deactive'}
                          {...register('status', { required: true })}
                          // defaultChecked={OldData?.status === 0}
                        />
                        Inactive
                      </label>
                    </CCol>
                  </CRow>
                  <CRow className="p-2">
                    <div className="d-grid gap-2">
                      <CButton color="light" size="sm" type="submit">
                        Update
                      </CButton>
                      <CButton color="light" size="sm" onClick={() => navigate(-1)}>
                        Cancel
                      </CButton>
                    </div>
                  </CRow>
                </form>
              </CCardBody>
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </div>
  )
}

export default UpdateSubInterest
