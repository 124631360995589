const {
  REQ_USER_LIST,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,
  REQ_USER_DETAILS,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_ERROR,
  REQ_UPDATE_USER,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  REQ_REPORT_USER_LIST,
  REPORT_USER_LIST_SUCCESS,
  REPORT_USER_LIST_ERROR,
  SET_USER_TOAST,
} = require('./actionType')

const INIT_STATE = {
  usersList: null,
  userError: null,
  userToggle: false,
  userDetails: null,
  userDetailsError: null,
  updateUser: null,
  updateUserError: null,
  reportUsersList: null,
  toast: { visible: false, type: '', message: '' },
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQ_USER_LIST:
      return {
        ...state,
        userToggle: true,
      }
    case USER_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        userToggle: false,
      }
    case USER_LIST_ERROR:
      return {
        ...state,
        userError: action.payload,
        userToggle: false,
      }
    case REQ_USER_DETAILS:
      return {
        ...state,
        userToggle: true,
      }
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        userToggle: false,
      }
    case USER_DETAILS_ERROR:
      return {
        ...state,
        userDetailsError: action.payload,
        userToggle: false,
      }
    case REQ_UPDATE_USER:
      return {
        ...state,
        userToggle: true,
      }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        updateUser: action.payload,
        userToggle: false,
      }
    case USER_UPDATE_ERROR:
      return {
        ...state,
        updateUserError: action.payload,
        userToggle: false,
      }
    case REQ_REPORT_USER_LIST:
      return {
        ...state,
        userToggle: true,
      }
    case REPORT_USER_LIST_SUCCESS:
      return {
        ...state,
        reportUsersList: action.payload,
        userToggle: false,
      }
    case REPORT_USER_LIST_ERROR:
      return {
        ...state,
        userError: action.payload,
        userToggle: false,
      }
    case SET_USER_TOAST:
      return {
        ...state,
        toast: action.payload,
      }
    default:
      return state
  }
}

export default Users
