// src/store/reducers.js

import {
  ADD_BANNER_SUCCESS,
  ADD_BANNER_ERROR,
  BANNER_DETAILS_SUCCESS,
  BANNER_DETAILS_ERROR,
  BANNER_LIST_SUCCESS,
  BANNER_LIST_ERROR,
  BANNER_UPDATE_SUCCESS,
  BANNER_UPDATE_ERROR,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_ERROR,
  REQ_BANNER_LIST,
  REQ_ADD_BANNER,
  REQ_DELETE_BANNER,
  REQ_UPDATE_BANNER,
  SET_TOAST,
} from './actionType'

const initialState = {
  banners: [],
  bannerDetails: null,
  loading: false,
  error: null,
  toast: { visible: false, type: '', message: '' },
}

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQ_ADD_BANNER:
      return {
        ...state,
        loading: true,
      }
    case ADD_BANNER_SUCCESS:
      return {
        ...state,
        banners: [...state.banners, action.payload],
        error: null,
        loading: false,
      }
    case ADD_BANNER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case REQ_BANNER_LIST:
      return {
        ...state,
        loading: true,
      }
    case BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        bannerDetails: action.payload,
        error: null,
        loading: false,
      }
    case BANNER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case BANNER_LIST_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        error: null,
        loading: false,
      }
    case BANNER_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case REQ_UPDATE_BANNER:
      return {
        ...state,
        loading: true,
      }
    case BANNER_UPDATE_SUCCESS:
      return {
        ...state,
        banners: state.banners.map((banner) =>
          banner.id === action.payload.id ? action.payload : banner,
        ),
        error: null,
        loading: false,
      }
    case BANNER_UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case REQ_DELETE_BANNER:
      return {
        ...state,
        loading: true,
      }
    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        banners: state.banners.filter((banner) => banner.id !== action.payload),
        error: null,
        loading: false,
      }
    case DELETE_BANNER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case SET_TOAST:
      return {
        ...state,
        toast: action.payload,
      }
    default:
      return state
  }
}

export default bannerReducer
