import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getCode, getData } from 'country-list'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'src/components/Loader'
import { reqCountryDetails, reqCountryUpdate } from 'src/store/action'
const UpdateCountryList = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const countryList = getData()
  const { register, handleSubmit, reset } = useForm()
  const [oldData, setOldData] = useState(null)
  const [ckData, setCkData] = useState({
    termsAndCondition: '',
    privacyPolicy: '',
  })

  const selectedCountry = useSelector((state) => state.Country?.detailCountry?.data)
  const isCountryListLoading = useSelector((state) => state.Country?.countryToggle)

  useEffect(() => {
    dispatch(reqCountryDetails(id))
  }, [dispatch, id])

  useEffect(() => {
    selectedCountry &&
      setTimeout(() => {
        setOldData({
          ...selectedCountry,
        })
        setCkData({
          termsAndCondition: selectedCountry?.termsAndCondition,
          privacyPolicy: selectedCountry?.privacyPolicy,
        })
      }, 1000)
  }, [selectedCountry])
  useEffect(() => {
    reset(oldData)
  }, [reset, oldData])
  const toast = useSelector((state) => state.Country?.toast)

  console.log('toast', toast)
  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_COUNTRY_TOAST', payload: { visible: false, type: '', message: '' } })
        if (toast.type == 'success') {
          navigate(-1)
        }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])
  const onSubmit = async (data) => {
    // const countryCode = getCode(data.country)
    const countryName = data.countryName
    const termsAndCondition = ckData.termsAndCondition
    const privacyPolicy = ckData.privacyPolicy
    const AmbulancHelpLineContact = data.AmbulancHelpLineContact
    const cancellationPolicy = data.cancellationPolicy
    const sosLimitCount = data.sosLimitCount
    const policeHelpLineContact = data.policeHelpLineContact
    const fireHelpLineContact = data.fireHelpLineContact
    const likesLimits = data.likesLimits
    const distanceUnit = data.distanceUnit
    const otpLimit = data.otpLimit
    const otpExpireTime = data.otpExpireTime
    const resetOtpAfter = data.resetOtpAfter
    const weightUnit = data.weightUnit
    const heightUnit = data.heightUnit
    const newData = {
      id: id,
      countryName,
      // countryCode,
      termsAndCondition,
      privacyPolicy,
      AmbulancHelpLineContact,
      cancellationPolicy,
      sosLimitCount,
      policeHelpLineContact,
      fireHelpLineContact,
      likesLimits,
      distanceUnit,
      otpLimit,
      otpExpireTime,
      resetOtpAfter,
      weightUnit,
      heightUnit,
    }

    dispatch(reqCountryUpdate(newData, navigate))
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" onClick={() => navigate(-1)}>
            Back
          </CButton>
          {/* {isCountryListLoading && <Loader />} */}

          {!isCountryListLoading && (
            <CRow>
              <CCol xs={12}>
                <CCardBody>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Country Name</label>
                      <CCol sm={10}>
                        <select
                          className="form-control m-1 p-2"
                          required
                          {...register('countryName', { required: true })}
                          defaultValue={oldData?.countryName} // Set the default value to the currently selected country name
                        >
                          <option value="">Choose country</option>
                          {countryList?.map((country) => (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">SOS Limit Count</label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('sosLimitCount')}
                          type="number"
                          placeholder="sos Limit Count"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">likesLimits</label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('likesLimits')}
                          type="number"
                          placeholder="add like limit"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Police HelpLine</label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('policeHelpLineContact')}
                          type="number"
                          placeholder="police HelpLine"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Police HelpLine</label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('fireHelpLineContact')}
                          type="number"
                          placeholder="fire HelpLine"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Ambulanc HelpLine</label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('AmbulancHelpLineContact')}
                          type="number"
                          placeholder="Ambulanc HelpLine"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Distance Unit</label>
                      <CCol sm={10}>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'Km'}
                            {...register('distanceUnit', { required: true })}
                          />
                          Km
                        </label>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'Miles'}
                            {...register('distanceUnit', { required: true })}
                          />
                          Miles
                        </label>
                      </CCol>
                    </CRow>
                    <CRow className="mt-2 form-group">
                      <label className="col-sm-2 col-form-label">Terms & Conditions</label>
                      <CCol sm={10}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={oldData?.termsAndCondition}
                          onChange={(event, editor) => {
                            let data = editor.getData()
                            data = data
                            setCkData({ ...ckData, termsAndCondition: data })
                          }}
                          placeholder="termsAndCondition"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mt-2 form-group">
                      <label className="col-sm-2 col-form-label">privacy policy</label>
                      <CCol sm={10}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={oldData?.privacyPolicy}
                          config={{
                            removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
                          }}
                          onChange={(event, editor) => {
                            let data = editor.getData()
                            data = data
                            setCkData({ ...ckData, privacyPolicy: data })
                          }}
                          placeholder="privacyPolicy"
                          className="form-control m-1 p-2"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Resend Attempts</label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('otpLimit')}
                          type="number"
                          placeholder="add resend limit"
                        />
                      </CCol>
                    </CRow>

                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">
                        OTP Expire Time (In seconds)
                      </label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('otpExpireTime')}
                          type="number"
                          placeholder="add expire time limit"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">
                        Resend Block Time (In seconds)
                      </label>
                      <CCol sm={10}>
                        <input
                          className="form-control m-1 p-2"
                          {...register('resetOtpAfter')}
                          type="number"
                          placeholder="add reset expire otp time"
                        />
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Weight Unit</label>
                      <CCol sm={10}>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'kg'}
                            {...register('weightUnit', { required: true })}
                          />
                          Kg
                        </label>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'lbs'}
                            {...register('weightUnit', { required: true })}
                          />
                          Lbs
                        </label>
                      </CCol>
                    </CRow>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Height Unit</label>
                      <CCol sm={10}>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'feet'}
                            {...register('heightUnit', { required: true })}
                          />
                          Feet
                        </label>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="mr-2"
                            type="radio"
                            value={'centimeters'}
                            {...register('heightUnit', { required: true })}
                          />
                          Centimeters
                        </label>
                      </CCol>
                    </CRow>
                    <CRow className="p-2">
                      <div className="d-grid gap-2">
                        <CButton color="light" size="sm" type="submit">
                          Update
                        </CButton>
                        <CButton color="light" size="sm" onClick={() => navigate(-1)}>
                          Cancel
                        </CButton>
                      </div>
                    </CRow>
                  </form>
                </CCardBody>
              </CCol>
            </CRow>
          )}
        </div>
        <AppFooter />
      </div>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </div>
  )
}
export default UpdateCountryList
