import {
  REQ_UPDATE_USER,
  REQ_USER_DETAILS,
  REQ_USER_LIST,
  USER_DETAILS_ERROR,
  USER_DETAILS_SUCCESS,
  USER_LIST_ERROR,
  USER_LIST_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_SUCCESS,
  REQ_REPORT_USER_LIST,
  REPORT_USER_LIST_SUCCESS,
  REPORT_USER_LIST_ERROR,
  SET_USER_TOAST,
} from './actionType'

export const reqUserList = (req) => {
  return {
    type: REQ_USER_LIST,
    payload: req,
  }
}

export const userListSuccess = (req) => {
  return {
    type: USER_LIST_SUCCESS,
    payload: req,
  }
}

export const userListError = (req) => {
  return {
    type: USER_LIST_ERROR,
    payload: req,
  }
}

export const reqUserDetails = (req) => {
  return {
    type: REQ_USER_DETAILS,
    payload: req,
  }
}

export const userDetailsSuccess = (res) => {
  return {
    type: USER_DETAILS_SUCCESS,
    payload: res,
  }
}

export const userDetailsError = (res) => {
  return {
    type: USER_DETAILS_ERROR,
    payload: res,
  }
}

export const reqUserUpdate = (req) => {
  return {
    type: REQ_UPDATE_USER,
    payload: req,
  }
}

export const updateUserSucces = (req) => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload: req,
  }
}

export const updateUserError = (req) => {
  return {
    type: USER_UPDATE_ERROR,
    payload: req,
  }
}

export const reqReportUserList = (req) => {
  return {
    type: REQ_REPORT_USER_LIST,
    payload: req,
  }
}

export const reportUserListSuccess = (req) => {
  return {
    type: REPORT_USER_LIST_SUCCESS,
    payload: req,
  }
}

export const reportUserListError = (req) => {
  return {
    type: REPORT_USER_LIST_ERROR,
    payload: req,
  }
}
export const setUserToast = (toast) => ({
  type: SET_USER_TOAST,
  payload: toast,
})
