import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { CCardBody, CCol, CRow, CButton, CModal, CModalBody, CModalFooter } from '@coreui/react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqUserDetails, reqUserUpdate } from 'src/store/action'
import Loader from 'src/components/Loader'
const UserUpdate = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { register, handleSubmit, reset } = useForm()
  const isUserUpdated = useSelector((state) => state.Users?.userToggle)
  const userDetails = useSelector((state) => state.Users?.userDetails)
  const [OldData, setOldData] = useState(null)
  useEffect(() => {
    dispatch(reqUserDetails(id))
  }, [dispatch, id])
  useEffect(() => {
    userDetails && setTimeout(() => setOldData({ ...userDetails }), 1000)
  }, [userDetails])

  useEffect(() => {
    reset(OldData)
  }, [reset, OldData])
  const toast = useSelector((state) => state.Users?.toast)
  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_USER_TOAST', payload: { visible: false, type: '', message: '' } })
        if (toast.type == 'success') {
          navigate(-1)
        }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])
  const onSubmit = async (data) => {
    const finalData = {
      userId: id,
      status: data.status,
    }
    dispatch(reqUserUpdate(finalData))
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" className="m-2" onClick={() => navigate(-1)}>
            Back
          </CButton>
          {isUserUpdated && <Loader />}
          {!isUserUpdated && (
            <CRow>
              <CCol xs={12}>
                <CCardBody>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <CRow className="form-group">
                      <label className="col-sm-2 col-form-label">Status</label>
                      <CCol sm={10}>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="m-2"
                            type="radio"
                            value={'active'}
                            {...register('status', { required: true })}
                          />
                          Active
                        </label>
                        <label className="m-2" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="m-2"
                            type="radio"
                            value={'deactivate'}
                            {...register('status', { required: true })}
                          />
                          Inactive
                        </label>
                      </CCol>
                    </CRow>
                    <CRow className="p-2">
                      <div className="d-grid gap-2">
                        <CButton color="light" size="sm" type="submit">
                          Update
                        </CButton>
                        <CButton color="light" size="sm" onClick={() => navigate(-1)}>
                          cancel
                        </CButton>
                      </div>
                    </CRow>
                  </form>
                </CCardBody>
              </CCol>
            </CRow>
          )}
        </div>
        <AppFooter />
      </div>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </div>
  )
}

export default UserUpdate
