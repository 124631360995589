import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CModal,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import Moment from 'moment'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqCountryList, reqDeleteCountry } from 'src/store/action'
import Loader from 'src/components/Loader'
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'

const GetCountry = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const Country_data = useSelector((state) => state.Country?.countries)
  const isCountryListLoading = useSelector((state) => state.Country?.countryToggle)
  const [visible, setVisible] = useState(false) // Modal visibility
  const [selectedCountryId, setSelectedCountryId] = useState(null) // Selected country for deletion
  useEffect(() => {
    dispatch(reqCountryList())
  }, [dispatch])
  const toast = useSelector((state) => state.Country?.toast)

  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_COUNTRY_TOAST', payload: { visible: false, type: '', message: '' } })
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])
  const deleteCountry = (id) => {
    if (window.confirm(`Are you sure, you want to delele country permenently?`)) {
      dispatch(reqDeleteCountry(id))
    }
  }
  const handleDelete = () => {
    if (selectedCountryId) {
      dispatch(reqDeleteCountry(selectedCountryId))
    }
    setVisible(false) // Close modal after deletion
    setSelectedCountryId(null) // Reset selected country ID
  }

  const openDeleteModal = (id) => {
    setSelectedCountryId(id)
    setVisible(true)
  }
  const [searchText, setSearchText] = useState('')
  const columns = [
    { field: 'serial', headerName: 'S.No', width: 100 },
    { field: 'countryCode', headerName: 'Country Code', width: 150 },
    { field: 'countryName', headerName: 'Country Name', width: 200 },
    {
      field: 'updatedAt',
      headerName: 'Create at',
      width: 150,
      renderCell: (params) => {
        // Format the date using Moment.js
        return Moment(params.row.updatedAt).isValid()
          ? Moment(params.row.updatedAt).format('DD/MM/YYYY')
          : '' // Return an empty string if the date is invalid
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={() => navigate(`/updateCountryList/${params.row._id}`)}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={() => navigate(`/countryDetails/${params.row._id}`)}
          >
            Details
          </Button>
          <Button
            variant="outlined"
            color="error"
            // startIcon={<DeleteIcon />}
          >
            {/* <AiOutlineDelete onClick={() => openDeleteModal(params.row._id)} /> */}
            <DeleteIcon onClick={() => openDeleteModal(params.row._id)} />
          </Button>
        </div>
      ),
    },
  ]
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value)
  }

  console.log('Country_data', Country_data)
  const getFilteredRows = () => {
    return (
      Country_data &&
      Country_data.length > 0 &&
      Country_data?.map((row, index) => ({
        serial: index + 1,
        id: row._id,
        updatedAt: Moment(row?.updatedAt).format('DD/MM/YYYY'),
        ...row,
      })).filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    )
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              <CButton color="secondary" onClick={() => navigate('/addCountryList')}>
                Add Country
              </CButton>
              {isCountryListLoading && <Loader />}
              {!isCountryListLoading && Country_data?.length > 0 && (
                <CCardBody style={{ marginTop: '10px' }}>
                  <DataGrid
                    rows={getFilteredRows()}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                    disableColumnMenu
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1000,
                      },
                    }}
                  />
                </CCardBody>
              )}
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
      {/* Confirmation Modal */}
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalBody>Are you sure you want to delete this country?</CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={handleDelete}>
            Delete
          </CButton>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </div>
  )
}
export default GetCountry
