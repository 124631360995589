import axios from './axios'
// import { SERVER_URL } from '../helpers/api_url'

// const axios = axiosInstance

export const usersListApi = () => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/user`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const getUserDetails = (id) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/user/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const UpdatUsersList = (data) => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/user`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}

export const reportUsersApi = () => {
  const access_token = JSON.parse(sessionStorage.getItem('loginInfo'))
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/getListReportUser`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((err) => reject(err))
  })
}
