import { takeLatest, fork, put, all, call } from 'redux-saga/effects'
import {
  UpdatCountryList,
  addCountryData,
  deleteCountryApi,
  getCountryDetails,
  getCountryList,
} from 'src/helpers/callCountry'
import {
  CountryDetailError,
  CountryDetailSuccess,
  addCountryError,
  addCountrySuccess,
  countryListError,
  countryListSuccess,
  deleteCountryError,
  deleteCountrySuccess,
  updateCountryError,
  updateCountrySuccess,
  setCountryToast,
} from './action'
import {
  REQ_ADD_COUNTRY,
  REQ_COUNTRY_DETAILS,
  REQ_COUNTRY_LIST,
  REQ_DELETE_COUNTRY,
  REQ_UPDATE_COUNTRY,
} from './actionType'

function* addCountry({ payload: req, navigate }) {
  try {
    const response = yield call(addCountryData, req)
    yield put(addCountrySuccess(response))
    // navigate('/getCountry')
    yield put(
      setCountryToast({
        visible: true,
        type: 'success',
        message: 'Country added successfully! ·',
      }),
    )
  } catch (err) {
    yield put(addCountryError(err))
    yield put(
      setCountryToast({
        visible: true,
        type: 'error',
        message: 'Failed to add country! ·',
      }),
    )
  }
}

function* countryList() {
  try {
    const response = yield call(getCountryList)
    yield put(countryListSuccess(response))
  } catch (err) {
    yield put(countryListError(err))
  }
}

function* fetchCountryDetails({ payload: req }) {
  try {
    const response = yield call(getCountryDetails, req)
    yield put(CountryDetailSuccess(response))
  } catch (err) {
    yield put(CountryDetailError(err))
  }
}

function* updateCountry({ payload: req, navigate }) {
  try {
    const response = yield call(UpdatCountryList, req)
    yield put(updateCountrySuccess(response))
    // navigate('/getCountry')
    yield put(
      setCountryToast({
        visible: true,
        type: 'success',
        message: 'Country updated successfully! ·',
      }),
    )
  } catch (err) {
    yield put(updateCountryError(err))
    yield put(
      setCountryToast({
        visible: true,
        type: 'error',
        message: 'Failed to update country! ·',
      }),
    )
  }
}

function* deleteCountry({ payload: req }) {
  try {
    const response = yield call(deleteCountryApi, req)
    yield put(deleteCountrySuccess(response))
    yield put({ type: REQ_COUNTRY_LIST })
    yield put(
      setCountryToast({
        visible: true,
        type: 'success',
        message: 'Country deleted successfully! ·',
      }),
    )
  } catch (err) {
    yield put(deleteCountryError(err))
    yield put(
      setCountryToast({
        visible: true,
        type: 'error',
        message: 'Failed to delete country! ·',
      }),
    )
  }
}

function* watchCountryList() {
  yield takeLatest(REQ_COUNTRY_LIST, countryList)
}

function* watchCountryDetails() {
  yield takeLatest(REQ_COUNTRY_DETAILS, fetchCountryDetails)
}

function* watchCountryUpdate() {
  yield takeLatest(REQ_UPDATE_COUNTRY, updateCountry)
}

function* watchAddCountry() {
  yield takeLatest(REQ_ADD_COUNTRY, addCountry)
}

function* watchDeleteCountry() {
  yield takeLatest(REQ_DELETE_COUNTRY, deleteCountry)
}

function* countriesSaga() {
  yield all([
    fork(watchCountryList),
    fork(watchCountryDetails),
    fork(watchCountryUpdate),
    fork(watchAddCountry),
    fork(watchDeleteCountry),
  ])
}

export default countriesSaga
