import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import dummyInterest from '../interest/dummyData'
import { CCard, CCardBody, CCol, CRow, CButton } from '@coreui/react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqSubIntrest, reqSubIntrestDetails } from 'src/store/action'

const SubInterestDetails = () => {
  const { id, subInterestId } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const details = useSelector((state) => state.Intrest?.getSubintrestDetails?.subServices[0])
  useEffect(() => {
    dispatch(reqSubIntrestDetails({ id, subInterestId }))
  }, [dispatch, id, subInterestId])
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CButton color="secondary" onClick={() => navigate(-1)}>
            Back
          </CButton>
          <CRow>
            <div className="col-12">
              <CCard>
                <CCardBody>
                  <CRow className="form-group align-items-center">
                    <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                      Sub Interest ID
                    </label>
                    <CCol sm={10}>
                      <h5>{details?._id || '-'}</h5>
                    </CCol>
                  </CRow>
                  {details?.subInterest?.map((e) => {
                    return (
                      <div key={e._id}>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Sub Interest Name
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.name || '-'}</h5>
                          </CCol>
                        </CRow>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Conditions
                          </label>
                          <CCol sm={10}>
                            {e?.Conditions ? (
                              <h5
                                dangerouslySetInnerHTML={{
                                  __html: e.Conditions,
                                }}
                              ></h5>
                            ) : (
                              <h5>-</h5>
                            )}
                          </CCol>
                        </CRow>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Description
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.description || '-'}</h5>
                          </CCol>
                        </CRow>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Description - 2
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.description_2 || '-'}</h5>
                          </CCol>
                        </CRow>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Description - 3
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.description_3 || '-'}</h5>
                          </CCol>
                        </CRow>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Status
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.status || '-'}</h5>
                          </CCol>
                        </CRow>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Pro
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.pro === false ? 'False' : 'True'}</h5>
                          </CCol>
                        </CRow>
                        {/* <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            created Date
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.created_at || '-'}</h5>
                          </CCol>
                        </CRow>
                        <CRow className="form-group align-items-center">
                          <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                            Update Date
                          </label>
                          <CCol sm={10}>
                            <h5>{e?.updatedAt || '-'}</h5>
                          </CCol>
                        </CRow> */}
                      </div>
                    )
                  })}
                </CCardBody>
              </CCard>
            </div>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default SubInterestDetails
