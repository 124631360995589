import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { reqReportUserList } from 'src/store/users/action'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import Loader from 'src/components/Loader'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react'
import Moment from 'moment'
const ReportUsersList = () => {
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usersData = useSelector((state) => state.Users?.reportUsersList) || []
  const isUserLoading = useSelector((state) => state.Users?.userToggle)
  console.log(isUserLoading)
  useEffect(() => {
    dispatch(reqReportUserList())
  }, [dispatch])

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              {isUserLoading && <Loader />}
              {!isUserLoading && (
                <CCardBody>
                  <CTable striped>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Reporter Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Reported Name</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Reason</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Timestamp</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    {usersData?.length > 0 && (
                      <CTableBody>
                        {usersData
                          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) // Sort by latest timestamp
                          .map((report, index) => (
                            <CTableRow key={index}>
                              <CTableDataCell>
                                {report.reportUser?.length > 0 &&
                                  `${report.reportUser[0].firstName} ${report.reportUser[0].lastName}`}
                              </CTableDataCell>
                              <CTableDataCell>
                                {report.reportedUser?.length > 0 &&
                                  `${report.reportedUser[0].firstName} ${report.reportedUser[0].lastName}`}
                              </CTableDataCell>
                              <CTableDataCell>{report.reason}</CTableDataCell>
                              <CTableDataCell>
                                {Moment(report.timestamp).format('DD/MM/YYYY')}
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                      </CTableBody>
                    )}
                  </CTable>
                </CCardBody>
              )}
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default ReportUsersList
