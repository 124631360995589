import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CModal,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import Moment from 'moment'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqIntrestDelete, reqIntrestList } from 'src/store/action'
import { SERVER_URL } from 'src/helpers/api_url'
import Loader from 'src/components/Loader'
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
const GetInterestList = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const details = useSelector((state) => state.Intrest?.intrestList)

  const isIntrestListLoading = useSelector((state) => state.Intrest?.intrestToggle)
  const [visible, setVisible] = useState(false) // Modal visibility
  const [selectedCountryId, setSelectedCountryId] = useState(null) // Selected country for deletion
  useEffect(() => {
    dispatch(reqIntrestList())
  }, [dispatch])
  const toast = useSelector((state) => state.Intrest?.toast)
  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_INTEREST_TOAST', payload: { visible: false, type: '', message: '' } })
        // if (toast.type == 'success') {
        //   navigate(-1)
        // }
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])
  const deleteCountry = (id) => {
    if (window.confirm(`Are you sure, you want to delele intrest permenently?`)) {
      dispatch(reqIntrestDelete(id, navigate))
    }
  }
  const handleDelete = () => {
    if (selectedCountryId) {
      dispatch(reqIntrestDelete(selectedCountryId, navigate))
    }
    setVisible(false) // Close modal after deletion
    setSelectedCountryId(null) // Reset selected country ID
  }

  const openDeleteModal = (id) => {
    setSelectedCountryId(id)
    setVisible(true)
  }
  const [searchText, setSearchText] = useState('')
  const columns = [
    { field: 'serial', headerName: 'S.No', width: 100 },
    {
      field: 'imageUrl',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => (
        <img
          src={params.row.imageUrl || '/images/no-image.jpg'}
          alt="Banner"
          style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '5px' }}
        />
      ),
    },
    { field: 'name', headerName: 'Interest name', width: 150 },
    { field: 'countryCode', headerName: 'Country Code', width: 200 },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'updatedAt',
      headerName: 'Update Date',
      width: 150,
      renderCell: (params) => Moment(params.row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={() => navigate(`/updateInterest/${params.row._id}`)}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={() => navigate(`/getSubInterest/${params.row._id}`)}
          >
            Sub Interest
          </Button>
          <Button
            variant="outlined"
            color="error"
            // startIcon={<DeleteIcon />}
          >
            {/* <AiOutlineDelete onClick={() => openDeleteModal(params.row._id)} /> */}
            <DeleteIcon onClick={() => openDeleteModal(params.row._id)} />
          </Button>
        </div>
      ),
    },
  ]
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value)
  }
  const getFilteredRows = () => {
    if (!details || details.length === 0) return []
    return (
      details?.length > 0 &&
      details
        .map((row, index) => ({
          serial: index + 1,
          id: row._id,
          ...row,
        }))
        .filter((row) =>
          Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchText.toLowerCase()),
          ),
        )
    )
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              <CButton color="secondary" onClick={() => navigate('/addInterest')}>
                Add Interest
              </CButton>
              {isIntrestListLoading && <Loader />}
              {!isIntrestListLoading && details?.length > 0 && (
                <CCardBody>
                  <DataGrid
                    rows={getFilteredRows()}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                    disableColumnMenu
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1000,
                      },
                    }}
                  />
                </CCardBody>
              )}
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
      {/* Confirmation Modal */}
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalBody>Are you sure you want to delete this interest?</CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={handleDelete}>
            Delete
          </CButton>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </div>
  )
}

export default GetInterestList
