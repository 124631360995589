import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  CCardBody,
  CCol,
  CRow,
  CButton,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CModal,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import Moment from 'moment'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { reqDeleteBanner, reqBannerList } from 'src/store/action'
import Loader from 'src/components/Loader'
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
const BannerList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  const banners = useSelector((state) => state.Banner?.banners)
  const isBannerListLoading = useSelector((state) => state.Banner?.loading)
  const [visible, setVisible] = useState(false) // Modal visibility
  const [selectedCountryId, setSelectedCountryId] = useState(null) // Selected country for deletion
  useEffect(() => {
    dispatch(reqBannerList())
  }, [dispatch])
  const toast = useSelector((state) => state.Banner.toast)
  useEffect(() => {
    if (toast && toast.visible) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_TOAST', payload: { visible: false, type: '', message: '' } })
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [toast, dispatch])
  const deleteBanner = (id) => {
    if (window.confirm(`Are you sure you want to delete this banner permanently?`)) {
      dispatch(reqDeleteBanner(id, navigate))
    }
  }
  const handleDelete = () => {
    if (selectedCountryId) {
      dispatch(reqDeleteBanner(selectedCountryId, navigate))
    }
    setVisible(false) // Close modal after deletion
    setSelectedCountryId(null) // Reset selected country ID
  }

  const openDeleteModal = (id) => {
    setSelectedCountryId(id)
    setVisible(true)
  }

  const [searchText, setSearchText] = useState('')
  const columns = [
    { field: 'serial', headerName: 'S.No', width: 100 },
    {
      field: 'imagePath',
      headerName: 'Image',
      width: 150,
      renderCell: (params) => (
        <img
          src={params.row.imagePath || '/images/no-image.jpg'}
          alt="Banner"
          style={{ width: '70px', height: '70px', objectFit: 'cover', borderRadius: '5px' }}
        />
      ),
    },
    { field: 'countryCode', headerName: 'Country Code', width: 150 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'updatedAt',
      headerName: 'Update Date',
      width: 150,
      renderCell: (params) => Moment(params.row.updatedAt).format('DD/MM/YYYY'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={() => navigate(`/updateBanner/${params.row._id}`)}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="error"
            // startIcon={<DeleteIcon />}
          >
            {/* <AiOutlineDelete onClick={() => openDeleteModal(params.row._id)} /> */}
            <DeleteIcon onClick={() => openDeleteModal(params.row._id)} />
          </Button>
        </div>
      ),
    },
  ]

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value)
  }
  const getFilteredRows = () => {
    if (!banners || banners.length === 0) return []

    return banners
      .map((row, index) => ({
        id: row._id || index, // Ensure a unique ID is provided
        serial: index + 1,
        ...row,
      }))
      .filter((row) =>
        Object.values(row).some((value) =>
          value?.toString().toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CRow>
            <CCol xs={12}>
              <CButton color="secondary" onClick={() => navigate('/addBanner')}>
                Add Banner
              </CButton>
              {isBannerListLoading && <Loader />}
              {!isBannerListLoading && banners?.length > 0 && (
                <CCardBody>
                  <DataGrid
                    rows={getFilteredRows()}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 15, 25]}
                    disableColumnMenu
                    getRowId={(row) => row.id} // Ensure unique ID for each row
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1000,
                      },
                    }}
                  />
                </CCardBody>
              )}
            </CCol>
          </CRow>
        </div>
        <AppFooter />
      </div>
      {/* Confirmation Modal */}
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalBody>Are you sure you want to delete this banner?</CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={handleDelete}>
            Delete
          </CButton>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      {/* Confirmation Modal */}
      <CModal visible={toast.visible}>
        <CModalBody>{toast.message}</CModalBody>
      </CModal>
    </div>
  )
}

export default BannerList
