import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  API_ERROR,
  GENERATE_TOKEN,
  GENERATE_TOKEN_SUCCESS,
  SET_AUTH_TOAST,
} from './actionTypes'

const initialState = {
  token: null,
  error: null,
  loading: false,
  apiData: null,
  toast: { visible: false, type: '', message: '' },
}
const login = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_TOKEN:
      state = {
        ...state,
        loading: true,
      }
      break
    case GENERATE_TOKEN_SUCCESS:
      state = {
        ...state,
        token: action.payload,
        loading: false,
      }
      break
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        apiData: action.payload,
        loading: false,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case SET_AUTH_TOAST:
      state = {
        ...state,
        toast: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
